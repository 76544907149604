import React from "react";
import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div style={{ minHeight: "700px", textAlign: "center", margin: "40px" }}>
      <h1>404 NOT FOUND</h1>
    </div>
  </Layout>
);

export default NotFoundPage;
